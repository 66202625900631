<template>
    <div class="flex flex-col flex-1 px-6">
        <div v-if="showButtons" class="flex justify-between items-center header">
            <div class="flex items-center">
                <div class="button button-big mr-4"/>
                <div class="button button-small mr-4"/>
                <div class="button button-small"/>
            </div>
            <div class="flex items-center justify-end">
                <div class="button button-big mr-4"/>
                <div class="button button-icon"/>
            </div>
        </div>
        <div id="loader-content" class="h-full">
            <table class="table-loader w-full border border-gray-200 rounded-lg">
                <tr id="loader-table-header" v-if="showHeader">
                    <th v-if="withEditCheckbox" class="col1">
                        <span></span>
                    </th>
                    <th v-for="(column, index) in selectColumns" :key="'loader-header-' + column.key + '-' + index" :style="{width: widthPercentage(column.width) + '%'}">
                        <span></span>
                    </th>
                </tr>
                <tr id="loader-table-content" v-for="row in Array(totalRows).keys()" :key="row">
                    <td v-if="withEditCheckbox" class="col1">
                        <span></span>
                    </td>
                    <td v-for="(column, index) in selectColumns" :key="'loader-content-' + column.key + '-' + index" :style="{width: widthPercentage(column.width) + '%'}">
                        <span></span>
                    </td>
                </tr>
            </table>
        </div>
    </div>
</template>

<script>
import {reduce} from "lodash";

export default {
    name: "loading-table",
    data() {
        return {
            totalRows: 1
        }
    },
    props: {
        showHeader: {type: Boolean, default: true},
        showButtons: {type: Boolean, default: true},
        withEditCheckbox: {type: Boolean, default: true},
        columns: {
            type: Object, default: () => {
            }
        },
    },
    computed: {
        selectColumns() {
            return Object.keys(this.$props.columns).map(c => {
                return {
                    item: c,
                    name: this.columns[c].title,
                    key: this.columns[c].id,
                    subKey: this.columns[c].subColumn,
                    width: this.columns[c].width
                }
            });
        },
        totalWidth() {
            return reduce(this.selectColumns, (sum, n) => sum + n.width, 0);
        }
    },

    methods: {
        widthPercentage(width) {
            return (width / this.totalWidth) * 100
        },

        calculateRows() {
            const content = document.getElementById("loader-content");
            console.log(content.clientHeight)
            const tableHeader = document.getElementById("loader-table-header");
            const tableRow = document.getElementById("loader-table-content");

            this.totalRows = Math.floor((content.clientHeight - tableHeader.clientHeight) / tableRow.clientHeight);
        }
    },

    mounted() {
        this.calculateRows()
    }
}
</script>

<style scoped>
.table-loader {
    background-color: #FFF;
    color: #666;
    width: 100%;
    padding: 0;
}

#loader-table-header th {
    height: 65px;
}

.table-loader th {
    background: #F7F9FA;
    border-top: 1px solid #E5E5E5;
    border-bottom: 1px solid #E5E5E5;
    padding: 12px 10px;
    text-align: left;
}

.table-loader td {
    border-top: 0px solid #E5E5E5;
    border-bottom: 1px solid #E5E5E5;
    padding: 16px 10px;
    vertical-align: top;
}

.header {
    padding: 16px 0px;
    margin-bottom: 15px;
    width: calc(100% - 5px);
}

.header .button {
    background-image: linear-gradient(90deg, #ddd 0px, #e8e8e8 40px, #ddd 80px);
    background-size: 60px;
    animation: shine-lines-table 1.6s infinite linear;
}

.header .button-big {
    height: 40px;
    width: 150px;
}

.header .button-small {
    height: 20px;
    width: 150px;
}

.header .button-icon {
    height: 40px;
    width: 40px;
}

.table-loader span {
    width: calc(100% - 5px);
    height: 15px;
    border-radius: 3px;
    background-image: linear-gradient(90deg, #ddd 0px, #e8e8e8 40px, #ddd 80px);
    background-size: 600px;
    animation: shine-lines-table 1.6s infinite linear;
    display: inline-block;
    min-width: 15px;
}

.col1 {
    width: 25px;
}


@keyframes shine-lines-table {
    0% {
        opacity: 0.2;
    }
    40% {
        opacity: 0.8;
    }
    100% {
        opacity: 0.2;
    }
}
</style>